/**
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import { IssueProvider } from './src/context/IssueContext';

const offsetY = 100;

const getTargetOffset = (hash) => {
  const id = window.decodeURI(hash.replace('#', ''));
  if (id !== '') {
    const element = document.getElementById(id);
    if (element) {
      return window.pageYOffset + element.getBoundingClientRect().top - offsetY;
    }
  }
  return null;
};

export const onInitialClientRender = () => {
  setTimeout(() => {
    const offset = getTargetOffset(window.location.hash);
    if (offset !== null) {
      window.scrollTo(0, offset);
    }
  }, 40);
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const offset = getTargetOffset(location.hash);
  return offset !== null ? [0, offset] : true;
};

export const wrapPageElement = (args) => {
  return (
    <IssueProvider
      value={{
        issue: args.props.pageContext.issue,
        features: args.props.pageContext.features
      }}
    >
      {args.element}
    </IssueProvider>
  );
};
