// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-issues-fall-2019-cover-tsx": () => import("./../../../src/issues/fall-2019/cover.tsx" /* webpackChunkName: "component---src-issues-fall-2019-cover-tsx" */),
  "component---src-issues-fall-2019-departments-alumni-index-tsx": () => import("./../../../src/issues/fall-2019/departments/alumni/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-alumni-index-tsx" */),
  "component---src-issues-fall-2019-departments-annals-index-tsx": () => import("./../../../src/issues/fall-2019/departments/annals/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-annals-index-tsx" */),
  "component---src-issues-fall-2019-departments-dispatch-index-tsx": () => import("./../../../src/issues/fall-2019/departments/dispatch/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-dispatch-index-tsx" */),
  "component---src-issues-fall-2019-departments-forum-index-tsx": () => import("./../../../src/issues/fall-2019/departments/forum/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-forum-index-tsx" */),
  "component---src-issues-fall-2019-departments-in-print-index-tsx": () => import("./../../../src/issues/fall-2019/departments/in-print/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-in-print-index-tsx" */),
  "component---src-issues-fall-2019-departments-overheard-index-tsx": () => import("./../../../src/issues/fall-2019/departments/overheard/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-overheard-index-tsx" */),
  "component---src-issues-fall-2019-departments-philanthropy-index-tsx": () => import("./../../../src/issues/fall-2019/departments/philanthropy/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-philanthropy-index-tsx" */),
  "component---src-issues-fall-2019-departments-real-world-index-tsx": () => import("./../../../src/issues/fall-2019/departments/real-world/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-departments-real-world-index-tsx" */),
  "component---src-issues-fall-2019-features-a-watershed-moment-index-tsx": () => import("./../../../src/issues/fall-2019/features/a-watershed-moment/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-features-a-watershed-moment-index-tsx" */),
  "component---src-issues-fall-2019-features-confronting-a-crisis-index-tsx": () => import("./../../../src/issues/fall-2019/features/confronting-a-crisis/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-features-confronting-a-crisis-index-tsx" */),
  "component---src-issues-fall-2019-features-on-the-shoulders-of-giants-index-tsx": () => import("./../../../src/issues/fall-2019/features/on-the-shoulders-of-giants/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-features-on-the-shoulders-of-giants-index-tsx" */),
  "component---src-issues-fall-2019-features-through-the-years-index-tsx": () => import("./../../../src/issues/fall-2019/features/through-the-years/index.tsx" /* webpackChunkName: "component---src-issues-fall-2019-features-through-the-years-index-tsx" */),
  "component---src-issues-fall-2020-cover-tsx": () => import("./../../../src/issues/fall-2020/cover.tsx" /* webpackChunkName: "component---src-issues-fall-2020-cover-tsx" */),
  "component---src-issues-fall-2020-departments-dispatch-index-tsx": () => import("./../../../src/issues/fall-2020/departments/dispatch/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-departments-dispatch-index-tsx" */),
  "component---src-issues-fall-2020-departments-in-print-index-tsx": () => import("./../../../src/issues/fall-2020/departments/in-print/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-departments-in-print-index-tsx" */),
  "component---src-issues-fall-2020-departments-philanthropy-index-tsx": () => import("./../../../src/issues/fall-2020/departments/philanthropy/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-departments-philanthropy-index-tsx" */),
  "component---src-issues-fall-2020-departments-real-world-index-tsx": () => import("./../../../src/issues/fall-2020/departments/real-world/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-departments-real-world-index-tsx" */),
  "component---src-issues-fall-2020-features-65-years-of-sais-in-europe-index-tsx": () => import("./../../../src/issues/fall-2020/features/65-years-of-sais-in-europe/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-features-65-years-of-sais-in-europe-index-tsx" */),
  "component---src-issues-fall-2020-features-so-long-silos-index-tsx": () => import("./../../../src/issues/fall-2020/features/so-long-silos/index.tsx" /* webpackChunkName: "component---src-issues-fall-2020-features-so-long-silos-index-tsx" */),
  "component---src-issues-spring-2021-cover-tsx": () => import("./../../../src/issues/spring-2021/cover.tsx" /* webpackChunkName: "component---src-issues-spring-2021-cover-tsx" */),
  "component---src-issues-spring-2021-departments-dispatch-index-tsx": () => import("./../../../src/issues/spring-2021/departments/dispatch/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-departments-dispatch-index-tsx" */),
  "component---src-issues-spring-2021-departments-in-print-index-tsx": () => import("./../../../src/issues/spring-2021/departments/in-print/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-departments-in-print-index-tsx" */),
  "component---src-issues-spring-2021-departments-philanthropy-index-tsx": () => import("./../../../src/issues/spring-2021/departments/philanthropy/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-departments-philanthropy-index-tsx" */),
  "component---src-issues-spring-2021-departments-real-world-index-tsx": () => import("./../../../src/issues/spring-2021/departments/real-world/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-departments-real-world-index-tsx" */),
  "component---src-issues-spring-2021-features-hnc-35th-anniversary-index-tsx": () => import("./../../../src/issues/spring-2021/features/hnc-35th-anniversary/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-features-hnc-35th-anniversary-index-tsx" */),
  "component---src-issues-spring-2021-features-lasting-legacy-index-tsx": () => import("./../../../src/issues/spring-2021/features/lasting-legacy/index.tsx" /* webpackChunkName: "component---src-issues-spring-2021-features-lasting-legacy-index-tsx" */),
  "component---src-issues-winter-2019-cover-tsx": () => import("./../../../src/issues/winter-2019/cover.tsx" /* webpackChunkName: "component---src-issues-winter-2019-cover-tsx" */),
  "component---src-issues-winter-2019-departments-alumni-index-tsx": () => import("./../../../src/issues/winter-2019/departments/alumni/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-alumni-index-tsx" */),
  "component---src-issues-winter-2019-departments-annals-index-tsx": () => import("./../../../src/issues/winter-2019/departments/annals/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-annals-index-tsx" */),
  "component---src-issues-winter-2019-departments-dispatch-index-tsx": () => import("./../../../src/issues/winter-2019/departments/dispatch/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-dispatch-index-tsx" */),
  "component---src-issues-winter-2019-departments-forum-index-tsx": () => import("./../../../src/issues/winter-2019/departments/forum/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-forum-index-tsx" */),
  "component---src-issues-winter-2019-departments-in-print-index-tsx": () => import("./../../../src/issues/winter-2019/departments/in-print/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-in-print-index-tsx" */),
  "component---src-issues-winter-2019-departments-overheard-index-tsx": () => import("./../../../src/issues/winter-2019/departments/overheard/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-overheard-index-tsx" */),
  "component---src-issues-winter-2019-departments-philanthropy-index-tsx": () => import("./../../../src/issues/winter-2019/departments/philanthropy/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-philanthropy-index-tsx" */),
  "component---src-issues-winter-2019-departments-real-world-index-tsx": () => import("./../../../src/issues/winter-2019/departments/real-world/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-departments-real-world-index-tsx" */),
  "component---src-issues-winter-2019-features-on-the-shoulders-of-giants-index-tsx": () => import("./../../../src/issues/winter-2019/features/on-the-shoulders-of-giants/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-features-on-the-shoulders-of-giants-index-tsx" */),
  "component---src-issues-winter-2019-features-portals-to-connection-index-tsx": () => import("./../../../src/issues/winter-2019/features/portals-to-connection/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-features-portals-to-connection-index-tsx" */),
  "component---src-issues-winter-2019-features-why-is-the-world-so-chaotic-index-tsx": () => import("./../../../src/issues/winter-2019/features/why-is-the-world-so-chaotic/index.tsx" /* webpackChunkName: "component---src-issues-winter-2019-features-why-is-the-world-so-chaotic-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

