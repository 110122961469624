import React, { useContext } from 'react';

interface IssueContextType {
  issue: {
    name: string;
    slug: string;
    departments: string[];
  };
  features: {
    title: string;
    subtitle: string;
    label: string | null;
    slug: string;
    author: string;
    issue: string;
    animatedCover: string;
    image: {
      sizes: {
        thumb: {
          width: number;
          height: number;
          src: string;
          srcSet: string;
        };
        full: {
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  }[];
}

const IssueContext = React.createContext<IssueContextType>({
  issue: {
    name: 'Fall 2020',
    slug: 'fall-2020',
    departments: []
  },
  features: []
});

export const IssueProvider = IssueContext.Provider;

export const useCurrentIssue = (): IssueContextType => {
  return useContext(IssueContext);
};
